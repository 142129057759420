<template>
  <component :is="resolveLayoutVariant">
    <router-view></router-view>
    <file-upload-process></file-upload-process>
  </component>
</template>

<script>
import LayoutBlank from '@/layouts/LayoutBlank';
import LayoutContent from '@/layouts/LayoutContent';
import LayoutRegister from '@/layouts/LayoutRegister';
import FileUploadProcess from "@/components/FileUploadProcess.vue";

export default {
  name: 'App',

  components: {
    FileUploadProcess,
    LayoutBlank,
    LayoutRegister,
    LayoutContent,
    LayoutBase: () => import('@/layouts/LayoutBase'),
  },
  created() {
    if (this.$store.getters.token)
      this.check_user()


  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || 'ONLINEMED.uz';
      }
    },
  },
  methods: {
    async check_user() {
      // eslint-disable-next-line no-undef
      let response = await axios.get(`/api/me`, {
        headers: {
          Authorization: "Bearer " + this.$store.getters.token
        }
      })
      if (response?.data?.data) {
        this.$store.commit('setAuthUser', response.data?.data)
        this.$store.commit('setPermissions', response.data?.data?.permissions)
      }
    }
  },
  computed: {
    resolveLayoutVariant() {
      let route = this.$route
      let token = this.$store.getters.token
      let user = this.$store.getters.user
      if (route.meta.layout === 'register') return `layout-register`
      if (route.meta.layout === 'content') return `layout-content`
      if (route.meta.layout === 'blank' || !(token && user)) return 'layout-blank'

      return null
    }
  },
};
</script>
<style>

</style>