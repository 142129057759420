import axios from 'axios'

const state = {
    auth_user: {},
    auth_user_errors: {},
    token: '',
    refresh_token: '',
    permissions: [],
}

const getters = {
    auth_user: state => state.auth_user,
    auth_user_errors: state => state.auth_user_errors,
    token: state => state.token,
    refresh_token: state => state.refresh_token,
    permissions: state => state.permissions,
}

const mutations = {
    setAuthUser(state, auth_user) {
        state.auth_user = auth_user
    },
    setAuthUserLoading(state, loading) {
        state.auth_user_loading = loading
    },
    setAuthUserErrors(state, errors) {
        state.auth_user_errors = errors
    },
    setToken(state, token) {
        state.token = token
    },
    setPermissions(state, permissions) {
        state.permissions = permissions
    },
    setRefreshToken(state, token) {
        state.refresh_token = token
    },
    setStatsDoctorFilter(state, stats_doctors_filter) {
        state.stats_doctors_filter = stats_doctors_filter
    },
    setOrders(state, orders) {
        state.orders = orders
    },
    setOrdersLoading(state, orders_loading) {
        state.orders_loading = orders_loading
    },
}

const actions = {

    async checkLogin({commit}, data) {
        commit('setAuthUserErrors', {})
        try {
            let response = await axios.post('/api/check-login', data)
            return response.data.ok
        } catch (error) {
            commit('setAuthUserErrors', error.response.data.errors)
            return false
        }
    },
    async logout({commit}) {
        commit('setAuthUserErrors', {})
        try {
            let response = await axios.post('/api/logout', {})
            commit('setToken', '')
            commit('setAuthUser', {})
            window.localStorage.clear()
            return response.data.ok
        } catch (error) {
            commit('setAuthUserErrors', error.response.data.errors)
            commit('setToken', '')
            commit('setAuthUser', {})
            return false
        }
    },
    async login({commit}, data) {
        commit('setAuthUserErrors', {})
        try {
            let response = await axios.post('/api/login', data)
            if (response.data.ok) {
                commit('setAuthUser', response.data.data)
                commit('setPermissions', response.data.permissions)
                commit('setToken', response.data.token)
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.token;
                return true
            } else {
                return false
            }
        } catch (error) {
            console.log(error);
            commit('setAuthUserErrors', error.response.data.errors)
            return false
        }
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}