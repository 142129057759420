import axios from 'axios'

const state = {
    branch_arrival: {},
    branch_arrivals: {},
    branch_arrival_errors: {},
    branch_arrival_drugs: {},
    branch_arrivals_loading: false,
}

const getters = {
    branch_arrival: state => state.branch_arrival,
    branch_arrivals: state => state.branch_arrivals,
    branch_arrivals_loading: state => state.branch_arrivals_loading,
    branch_arrival_errors: state => state.branch_arrival_errors,
    branch_arrival_drugs: state => state.branch_arrival_drugs,
}

const mutations = {
    setBranchArrival(state, branch_arrival) {
        state.branch_arrival = branch_arrival
    },
    setBranchArrivalDrugs(state, branch_arrival_drugs) {
        state.branch_arrival_drugs = branch_arrival_drugs
    },
    setBranchArrivals(state, branch_arrivals) {
        state.branch_arrivals = branch_arrivals
    },
    setBranchArrivalsLoading(state, branch_arrivals_loading) {
        state.branch_arrivals_loading = branch_arrivals_loading
    },
    setBranchArrivalErrors(state, errors) {
        state.branch_arrival_errors = errors
    },
}

const actions = {
    async getBranchArrivals({commit, getters}, params) {
        if (getters.permissions?.includes('branch_arrivals.index')) {
            commit('setBranchArrivalErrors', {})
            commit('setBranchArrivalsLoading', true)
            try {
                let response = await axios.get('/api/branch-arrivals', {params})
                commit('setBranchArrivals', response.data)
                commit('setBranchArrivalsLoading', false)


            } catch (error) {
                commit('setBranchArrivalErrors', error.response.data.errors)
                return false
            }
        }
    },
    async getBranchArrival({commit}, id) {
        commit('setBranchArrivalErrors', {})
        commit('setBranchArrivalsLoading', true)
        try {
            let response = await axios.get(`/api/branch-arrivals/${id}/show`)
            commit('setBranchArrival', response.data)
            commit('setBranchArrivalsLoading', false)
        } catch (error) {
            commit('setBranchArrivalErrors', error.response.data.errors)
            return false
        }
    },
    async getBranchArrivalDrugs({commit}, params) {
        // if (getters.permissions?.includes('branch_arrivals.drugs')) {
            commit('setBranchArrivalErrors', {})
            commit('setBranchArrivalsLoading', true)
            try {
                let response = await axios.get(`/api/branch-arrivals/${params.id}/drugs`, {params})
                commit('setBranchArrivalDrugs', response.data)
                commit('setBranchArrivalsLoading', false)
            } catch (error) {
                commit('setBranchArrivalErrors', error.response.data.errors)
                return false
            }
        // }
    },
    async storeBranchArrival({commit}, data) {
        commit('setBranchArrivalErrors', {})
        commit('setBranchArrivalsLoading', true)
        try {
            let response = await axios.post('/api/branch-arrivals/store', data)
            commit('setBranchArrivalsLoading', false)
            return response.data
        } catch (error) {
            commit('setBranchArrivalErrors', error.response.data.errors)
            commit('setBranchArrivalsLoading', false)

            return false
        }
    },

    async updateBranchArrival({commit, dispatch}, data) {
        commit('setBranchArrivalErrors', {})
        commit('setBranchArrivalsLoading', true)
        try {
            let response = await axios.put(`/api/branch-arrivals/${data.id}/update`, data)
            dispatch('getBranchArrival', data.id)
            commit('setBranchArrivalsLoading', false)
            return response.data
        } catch (error) {
            commit('setBranchArrivalErrors', error.response.data.errors)
            commit('setBranchArrivalsLoading', false)
            return false
        }
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}