<template>
  <div>
    <v-snackbar
        :value="!this.uploading_file?.uploaded"
        v-if="this.uploading_file?.id"
        right
        color="primary"
        outlined
        rounded="lg"
        bottom
        timeout="-1"
    >
      <v-card elevation="0" color="transparent" class="pa-0">
        <v-card-title>Dorilar yuklanmoqda
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          {{ uploading_file?.text }} - {{ this.uploading_file?.percentage }}%
          <v-progress-linear class="mt-2" :value="this.uploading_file?.percentage ?? 0" color="primary"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-snackbar>
  </div>

</template>
<script>
import {debounce} from "vue2-leaflet";

export default {
  name: "FileUploadProcess",
  data() {
    return {
      snackbar: true,
      text: "Salom"
    }
  },
  mounted() {
    if (!this.uploading_file?.uploaded && this.uploading_file?.id)
      this.$store.dispatch('getUploadingFile', this.uploading_file?.id)
  },
  watch: {
    uploading_file: debounce(function () {
      if (!this.uploading_file?.uploaded)
        this.$store.dispatch('getUploadingFile', this.uploading_file?.id)
      else{
        window.location.reload()
      }
    }, 2000),
  },
  computed: {
    uploading_file() {
      return this.$store.getters.uploading_file
    },
  }
}
</script>
<style scoped>

</style>