import axios from 'axios'

const state = {
    warehouse: {},
    warehouse_drugs: {},
    warehouse_branches: {},
    warehouses: {},
    warehouse_errors: {},
    warehouses_loading: false,
}

const getters = {
    warehouse: state => state.warehouse,
    warehouse_drugs: state => state.warehouse_drugs,
    warehouse_branches: state => state.warehouse_branches,
    warehouses: state => state.warehouses,
    warehouses_loading: state => state.warehouses_loading,
    warehouse_errors: state => state.warehouse_errors,
}

const mutations = {
    setWarehouse(state, warehouse) {
        state.warehouse = warehouse
    },
    setWarehouseDrugs(state, warehouse_drugs) {
        state.warehouse_drugs = warehouse_drugs
    },
    setWarehouseBranches(state, warehouse_branches) {
        state.warehouse_branches = warehouse_branches
    },
    setWarehouses(state, warehouses) {
        state.warehouses = warehouses
    },
    setWarehousesLoading(state, warehouses_loading) {
        state.warehouses_loading = warehouses_loading
    },
    setWarehouseErrors(state, errors) {
        state.warehouse_errors = errors
    },
}

const actions = {
    async getWarehouses({commit, getters}, params) {
        if (getters.permissions?.includes('warehouses.index')) {
            commit('setWarehouseErrors', {})
            commit('setWarehousesLoading', true)
            try {
                let response = await axios.get('/api/warehouses', {params})
                commit('setWarehouses', response.data)
                commit('setWarehousesLoading', false)


            } catch (error) {
                commit('setWarehouseErrors', error.response.data.errors)
                return false
            }
        }
    },
    async getWarehouse({commit}, id) {
        commit('setWarehouseErrors', {})
        commit('setWarehousesLoading', true)
        try {
            let response = await axios.get(`/api/warehouses/${id}/show`)
            commit('setWarehouse', response.data)
            commit('setWarehousesLoading', false)
        } catch (error) {
            commit('setWarehouseErrors', error.response.data.errors)
            return false
        }
    },
    async getWarehouseDrugs({commit, getters}, params) {
        if (getters.permissions?.includes('warehouses.drugs')) {
            commit('setWarehouseErrors', {})
            commit('setWarehousesLoading', true)
            try {
                let response = await axios.get(`/api/warehouses/${params.id}/drugs`, {params})
                commit('setWarehouseDrugs', response.data)
                commit('setWarehousesLoading', false)
            } catch (error) {
                commit('setWarehouseErrors', error.response.data.errors)
                return false
            }
        }
    },
    async getWarehouseBranches({commit, getters}, params) {
        if (getters.permissions?.includes('warehouses.branches')) {
            commit('setWarehouseErrors', {})
            commit('setWarehousesLoading', true)
            try {
                let response = await axios.get(`/api/warehouses/${params.id}/branches`, {params})
                commit('setWarehouseBranches', response.data)
                commit('setWarehousesLoading', false)
            } catch (error) {
                commit('setWarehouseErrors', error.response.data.errors)
                return false
            }
        }
    },
    async getWarehouseBranchDrugs({commit}, params) {
        commit('setWarehouseErrors', {})
        commit('setWarehousesLoading', true)
        try {
            let response = await axios.get(`/api/warehouses/${params.id}/${params.branch_id}/drugs`, {params})
            commit('setWarehouseDrugs', response.data)
            commit('setWarehousesLoading', false)
        } catch (error) {
            commit('setWarehouseErrors', error.response.data.errors)
            return false
        }
    },
    async storeWarehouse({commit}, data) {
        commit('setWarehouseErrors', {})
        commit('setWarehousesLoading', true)
        try {
            let response = await axios.post('/api/warehouses/store', data)
            commit('setWarehousesLoading', false)
            return response.data
        } catch (error) {
            commit('setWarehouseErrors', error.response.data.errors)
            commit('setWarehousesLoading', false)

            return false
        }
    },

    async updateWarehouse({commit}, data) {
        commit('setWarehouseErrors', {})
        commit('setWarehousesLoading', true)
        try {
            let response = await axios.put(`/api/warehouses/${data.id}/update`, data)
            commit('setWarehousesLoading', false)
            return response.data
        } catch (error) {
            commit('setWarehouseErrors', error.response.data.errors)
            commit('setWarehousesLoading', false)
            return false
        }
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}