import axios from 'axios'

const state = {
    user: {},
    user_company: {},
    user_drugs: {},
    users: {},
    user_errors: {},
    user_company_errors: {},
    users_loading: false,
    user_company_loading: false,
}

const getters = {
    user: state => state.user,
    user_company: state => state.user_company,
    users: state => state.users,
    users_loading: state => state.users_loading,
    user_company_loading: state => state.user_company_loading,
    user_errors: state => state.user_errors,
    user_company_errors: state => state.user_company_errors,
}

const mutations = {
    setUser(state, user) {
        state.user = user
    },
    setUserCompany(state, user_company) {
        state.user_company = user_company
    },
    setUsers(state, users) {
        state.users = users
    },
    setUsersLoading(state, users_loading) {
        state.users_loading = users_loading
    },
    setUserCompanyLoading(state, user_company_loading) {
        state.user_company_loading = user_company_loading
    },
    setUserErrors(state, errors) {
        state.user_errors = errors
    },
    setUserCompanyErrors(state, errors) {
        state.user_company_errors = errors
    },
}

const actions = {
    async getUsers({commit}, params) {
        commit('setUserErrors', {})
        commit('setUsersLoading', true)
        try {
            let response = await axios.get('/api/users', {params})
            commit('setUsers', response.data)
            commit('setUsersLoading', false)


        } catch (error) {
            commit('setUserErrors', error.response.data.errors)
            return false
        }
    },
    async getUser({commit}, id) {
        commit('setUserErrors', {})
        commit('setUsersLoading', true)
        try {
            let response = await axios.get(`/api/users/${id}/show`)
            commit('setUser', response.data)
            commit('setUsersLoading', false)
        } catch (error) {
            commit('setUserErrors', error.response.data.errors)
            return false
        }
    },
    async getUserMe({commit}) {
        commit('setUserErrors', {})
        commit('setUsersLoading', true)
        try {
            let response = await axios.get(`/api/users/me`)
            commit('setUser', response.data)
            commit('setUsersLoading', false)
        } catch (error) {
            commit('setUserErrors', error.response.data.errors)
            return false
        }
    },
    async getUserCompany({commit}) {
        commit('setUserErrors', {})
        commit('setUsersLoading', true)
        try {
            let response = await axios.get(`/api/users/company`)
            commit('setUserCompany', response.data)
            commit('setUsersLoading', false)
        } catch (error) {
            commit('setUserErrors', error.response.data.errors)
            return false
        }
    },
    async destroyUser({commit}, id) {
        commit('setUserErrors', {})
        commit('setUsersLoading', true)
        try {
            await axios.delete(`/api/users/${id}/destroy`)
            commit('setUsersLoading', false)
        } catch (error) {
            commit('setUserErrors', error.response.data.errors)
            return false
        }
    },
    async storeUser({commit}, data) {
        commit('setUserErrors', {})
        commit('setUsersLoading', true)
        try {
            let response = await axios.post('/api/users/store', data)
            commit('setUsersLoading', false)
            return response.data
        } catch (error) {
            commit('setUserErrors', error.response.data.errors)
            commit('setUsersLoading', false)

            return false
        }
    },

    async updateUser({commit}, data) {
        commit('setUserErrors', {})
        commit('setUsersLoading', true)
        try {
            let response = await axios.put(`/api/users/${data.id}/update`, data)
            commit('setUsersLoading', false)
            return response.data
        } catch (error) {
            commit('setUserErrors', error.response.data.errors)
            commit('setUsersLoading', false)
            return false
        }
    },
    async updateUserMe({commit}, data) {
        commit('setUserErrors', {})
        commit('setUsersLoading', true)
        try {
            let response = await axios.put(`/api/users/me`, data)
            commit('setUsersLoading', false)
            return response.data
        } catch (error) {
            commit('setUserErrors', error.response.data.errors)
            commit('setUsersLoading', false)
            return false
        }
    },
    async updateUserCompany({commit}, data) {
        commit('setUserCompanyErrors', {})
        commit('setUserCompanyLoading', true)
        try {
            let response = await axios.post(`/api/users/store-company`, data)
            commit('setUserCompanyLoading', false)
            return response.data
        } catch (error) {
            commit('setUserCompanyErrors', error.response.data.errors)
            commit('setUserCompanyLoading', false)
            return false
        }
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}