import axios from 'axios'

const state = {
    sale: {},
    sales: {},
    sale_errors: {},
    sale_drugs: {},
    sales_loading: false,
}

const getters = {
    sale: state => state.sale,
    sales: state => state.sales,
    sales_loading: state => state.sales_loading,
    sale_errors: state => state.sale_errors,
    sale_drugs: state => state.sale_drugs,
}

const mutations = {
    setSale(state, sale) {
        state.sale = sale
    },
    setSaleDrugs(state, sale_drugs) {
        state.sale_drugs = sale_drugs
    },
    setSales(state, sales) {
        state.sales = sales
    },
    setSalesLoading(state, sales_loading) {
        state.sales_loading = sales_loading
    },
    setSaleErrors(state, errors) {
        state.sale_errors = errors
    },
}

const actions = {
    async getSales({commit}, params) {
        commit('setSaleErrors', {})
        commit('setSalesLoading', true)
        try {
            let response = await axios.get('/api/sales', {params})
            commit('setSales', response.data)
            commit('setSalesLoading', false)


        } catch (error) {
            commit('setSaleErrors', error.response.data.errors)
            return false
        }
    },
    async getSale({commit}, id) {
        commit('setSaleErrors', {})
        commit('setSalesLoading', true)
        try {
            let response = await axios.get(`/api/sales/${id}/show`)
            commit('setSale', response.data)
            commit('setSalesLoading', false)
        } catch (error) {
            commit('setSaleErrors', error.response.data.errors)
            return false
        }
    },
    async getSaleDrugs({commit}, params) {
        commit('setSaleErrors', {})
        commit('setSalesLoading', true)
        try {
            let response = await axios.get(`/api/sales/${params.id}/drugs`, {params})
            commit('setSaleDrugs', response.data)
            commit('setSalesLoading', false)
        } catch (error) {
            commit('setSaleErrors', error.response.data.errors)
            return false
        }
    },
    async storeSale({commit}, data) {
        commit('setSaleErrors', {})
        commit('setSalesLoading', true)
        try {
            let response = await axios.post('/api/sales/store', data)
            commit('setSalesLoading', false)
            return response.data
        } catch (error) {
            commit('setSaleErrors', error.response.data.errors)
            commit('setSalesLoading', false)

            return false
        }
    },

    async updateSale({commit}, data) {
        commit('setSaleErrors', {})
        commit('setSalesLoading', true)
        try {
            let response = await axios.put(`/api/sales/${data.id}/update`, data)
            commit('setSalesLoading', false)
            return response.data
        } catch (error) {
            commit('setSaleErrors', error.response.data.errors)
            commit('setSalesLoading', false)
            return false
        }
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}