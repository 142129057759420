import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import VueTheMask from 'vue-the-mask'
import store from './store'
import ax from 'axios'
import '/public/css/main.css';
import i18n from './i18n'
import 'sweetalert2/dist/sweetalert2.min.css';
import 'leaflet/dist/leaflet.css';
import { Icon } from 'leaflet';

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});
import axios from "axios";
import VueSweetalert2 from 'vue-sweetalert2';
import {debounce} from "@/store/debounce";
window.axios = ax;
window.axios.defaults.baseURL = process.env.VUE_APP_API_URL;

window.permissions = store.getters.permissions

Vue.use(VueTheMask)
Vue.use(VueSweetalert2);


Vue.config.productionTip = false

Vue.use(require('vue3-shortkey'))

new Vue({
    vuetify,
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')

axios.interceptors.request.use(function (config) {
    config.headers.Authorization = 'Bearer ' + store.getters.token;
    return config;
});
Vue.config.errorHandler = debounce(function (err) {
        // handle error
        // `info` is a Vue-specific error info, e.g. which lifecycle hook
        // the error was found in. Only available in 2.2.0+
    console.log(err);
}, 500)

let hours = 1;
let now = new Date().getTime();
let setupTime = localStorage.getItem('setupTime');
let user = store.getters.auth_user

if (setupTime == null) {
    localStorage.setItem('setupTime', now)
} else {
    if (now - setupTime > hours * 60 * 60 * 1000 && user) {
        localStorage.clear();
        localStorage.setItem('setupTime', now);
        window.location.href = "/login";

    }
}




// axios.interceptors.response.use(function (response) {
//     return response
// }, function (error) {
//     if (error.response.status === 401 || error.response.status === 403) {
//         if (!['/not-auth', '/', '/login', '/register', '/register/doctor', '/register/region',
//             '/forgot-password', '/new-password', '/password-code', '/privacy-policy', '/terms-of-use', '/not-yet'
//         ].includes(router.currentRoute.path)) {
//             router.push('/not-auth')
//         }
//     }
//     return Promise.reject(error)
// })