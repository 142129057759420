<template>
    <v-app style="position: relative">
        <SideBar style="z-index: 152" ref="sidebar" :expanded="expanded"/>
        <img
                src="../assets/menu-icon-close.svg"
                alt=""
                @click="expanded = !expanded"
                class="menu-icon d-lg-block d-none"
                :style="expanded ? 'left: 284px' : 'left: 52px; transform: rotate(180deg);'"
        >
        <d-navbar style="z-index: 151; position:fixed; width: 100%; top: 0; background-color: #fff"/>
        <v-main style="margin-top: 68px;">
            <slot></slot>
        </v-main>
    </v-app>

</template>

<script>
import SideBar from "@/components/SideBar.vue";

export default {
    name: "LayoutContent",
    components: {
        SideBar,
        DNavbar: () => import('@/components/DNavbar.vue')
    },
    data() {
        return {
            expanded: true
        }
    }
}
</script>

<style scoped>
.menu-icon {
    position: fixed;
    top: 20px;
    z-index: 153;
    cursor: pointer;
    transition: 0.2s;
}

.menu-icon:hover {
    opacity: 0.8;
}

.menu-icon:active {
    opacity: 0.6;
}


</style>
