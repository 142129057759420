import Vue from "vue"
import VueRouter from "vue-router"
import routes from "./routes"
import store from '../store/index'

Vue.use(VueRouter)

const router = new VueRouter({
    routes, // short for `routes: routes`
    mode: 'history',
    linkActiveClass: "active", // active class for non-exact links.
    linkExactActiveClass: "active" // active class for *exact* links.

})

router.beforeEach((to, from, next) => {
    // console.log(to)
    // console.log(from)
    // console.log(next)
    // console.log('router.beforeEach')
    let token = store.getters.token
    if(to.meta.requires_auth) {
        if(token) {
            next()
        } else {
            next({name: 'login'})
        }
    } else {
        next()
    }
})


export default router
