import axios from 'axios'

const state = {
    branch_group: {},
    branch_group_drugs: {},
    branch_group_branches: {},
    branch_groups: {},
    branch_group_errors: {},
    branch_groups_loading: false,
}

const getters = {
    branch_group: state => state.branch_group,
    branch_group_drugs: state => state.branch_group_drugs,
    branch_group_branches: state => state.branch_group_branches,
    branch_groups: state => state.branch_groups,
    branch_groups_loading: state => state.branch_groups_loading,
    branch_group_errors: state => state.branch_group_errors,
}

const mutations = {
    setBranchGroup(state, branch_group) {
        state.branch_group = branch_group
    },
    setBranchGroupDrugs(state, branch_group_drugs) {
        state.branch_group_drugs = branch_group_drugs
    },
    setBranchGroupBranches(state, branch_group_branches) {
        state.branch_group_branches = branch_group_branches
    },
    setBranchGroups(state, branch_groups) {
        state.branch_groups = branch_groups
    },
    setBranchGroupsLoading(state, branch_groups_loading) {
        state.branch_groups_loading = branch_groups_loading
    },
    setBranchGroupErrors(state, errors) {
        state.branch_group_errors = errors
    },
}

const actions = {
    async getBranchGroups({commit, getters}, params) {
        if (getters.permissions?.includes('branch_groups.index')) {
            commit('setBranchGroupErrors', {})
            commit('setBranchGroupsLoading', true)
            try {
                let response = await axios.get('/api/branch-groups', {params})
                commit('setBranchGroups', response.data)
                commit('setBranchGroupsLoading', false)


            } catch (error) {
                commit('setBranchGroupErrors', error.response.data.errors)
                return false
            }
        }
    },
    async getBranchGroup({commit}, id) {
        commit('setBranchGroupErrors', {})
        commit('setBranchGroupsLoading', true)
        try {
            let response = await axios.get(`/api/branch-groups/${id}/show`)
            commit('setBranchGroup', response.data)
            commit('setBranchGroupsLoading', false)
        } catch (error) {
            commit('setBranchGroupErrors', error.response.data.errors)
            return false
        }
    },
    async getBranchGroupDrugs({commit, getters}, params) {
        if (getters.permissions?.includes('branch_groups.drugs')) {
            commit('setBranchGroupErrors', {})
            commit('setBranchGroupsLoading', true)
            try {
                let response = await axios.get(`/api/branch-groups/${params.id}/drugs`, {params})
                commit('setBranchGroupDrugs', response.data)
                commit('setBranchGroupsLoading', false)
            } catch (error) {
                commit('setBranchGroupErrors', error.response.data.errors)
                return false
            }
        }
    },
    async getBranchGroupBranches({commit, getters}, params) {
        if (getters.permissions?.includes('branch_groups.branches')) {
            commit('setBranchGroupErrors', {})
            commit('setBranchGroupsLoading', true)
            try {
                let response = await axios.get(`/api/branch-groups/${params.id}/branches`, {params})
                commit('setBranchGroupBranches', response.data)
                commit('setBranchGroupsLoading', false)
            } catch (error) {
                commit('setBranchGroupErrors', error.response.data.errors)
                return false
            }
        }
    },
    async getBranchGroupBranchDrugs({commit}, params) {
        commit('setBranchGroupErrors', {})
        commit('setBranchGroupsLoading', true)
        try {
            let response = await axios.get(`/api/branch-groups/${params.id}/${params.branch_id}/drugs`, {params})
            commit('setBranchGroupDrugs', response.data)
            commit('setBranchGroupsLoading', false)
        } catch (error) {
            commit('setBranchGroupErrors', error.response.data.errors)
            return false
        }
    },
    async storeBranchGroup({commit}, data) {
        commit('setBranchGroupErrors', {})
        commit('setBranchGroupsLoading', true)
        try {
            let response = await axios.post('/api/branch-groups/store', data)
            commit('setBranchGroupsLoading', false)
            return response.data
        } catch (error) {
            commit('setBranchGroupErrors', error.response.data.errors)
            commit('setBranchGroupsLoading', false)

            return false
        }
    },

    async updateBranchGroup({commit}, data) {
        commit('setBranchGroupErrors', {})
        commit('setBranchGroupsLoading', true)
        try {
            let response = await axios.put(`/api/branch-groups/${data.id}/update`, data)
            commit('setBranchGroupsLoading', false)
            return response.data
        } catch (error) {
            commit('setBranchGroupErrors', error.response.data.errors)
            commit('setBranchGroupsLoading', false)
            return false
        }
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}