import axios from 'axios'

const state = {
    arrival_drug: {},
    arrival_drugs: {},
    arrival_drug_errors: {},
    arrival_drugs_loading: false,
}

const getters = {
    arrival_drug: state => state.arrival_drug,
    arrival_drugs_loading: state => state.arrival_drugs_loading,
    arrival_drug_errors: state => state.arrival_drug_errors,
    arrival_drug_drugs: state => state.arrival_drug_drugs,
}

const mutations = {
    setArrivalDrug(state, arrival_drug) {
        state.arrival_drug = arrival_drug
    },
    setArrivalDrugs(state, arrival_drugs) {
        state.arrival_drugs = arrival_drugs
    },
    setArrivalDrugsLoading(state, arrival_drugs_loading) {
        state.arrival_drugs_loading = arrival_drugs_loading
    },
    setArrivalDrugErrors(state, errors) {
        state.arrival_drug_errors = errors
    },
}

const actions = {
    async storeArrivalDrug({commit}, data) {
        commit('setArrivalDrugErrors', {})
        commit('setArrivalDrugsLoading', true)
        try {
            let response = await axios.post('/api/arrival-drugs/store', data)
            commit('setArrivalDrugsLoading', false)
            return response.data
        } catch (error) {
            commit('setArrivalDrugErrors', error.response.data.errors)
            commit('setArrivalDrugsLoading', false)

            return false
        }
    },

    async updateArrivalDrug({commit}, data) {
        commit('setArrivalDrugErrors', {})
        commit('setArrivalDrugsLoading', true)
        try {
            let response = await axios.put(`/api/arrival-drugs/${data.id}/update`, data)
            commit('setArrivalDrugsLoading', false)
            return response.data
        } catch (error) {
            commit('setArrivalDrugErrors', error.response.data.errors)
            commit('setArrivalDrugsLoading', false)
            return false
        }
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}