import Vue from 'vue'
import Vuex from 'vuex'

import createPersistedState from "vuex-persistedstate";
import SecureLS from 'secure-ls'
import user from "@/store/user";
import drug from "@/store/drug";
import drug_provider from "@/store/drug_provider";
import branch from "@/store/branch";
import helpers from "@/store/helpers";
import warehouse from "@/store/warehouse";
import arrival from "@/store/arrival";
import arrival_drug from "@/store/arrival_drug";
import auth from "@/store/auth";
import role from "@/store/role";
import order from "@/store/order";
import branch_arrival from "@/store/branch_arrival";
import sale from "@/store/sale";
import family from "@/store/family";
import application from "@/store/application";
import act from "@/store/act";
import branch_outcome from "@/store/branch_outcome";
import branch_group from "@/store/branch_group";

const ls = new SecureLS({isCompression: false});
Vue.use(Vuex)


export default new Vuex.Store({
    modules: {
        branch_group,
        branch_outcome,
        act,
        application,
        family,
        sale,
        branch_arrival,
        order,
        role,
        auth,
        arrival_drug,
        arrival,
        warehouse,
        helpers,
        branch,
        drug_provider,
        user,
        drug,
    },
    state: {
        storage: process.env.VUE_APP_STORAGE,
    },
    getters: {
        storage: state => state.storage
    },
    mutations: {},
    plugins: [
        createPersistedState({
            storage: {
                getItem: key => ls.get(key),
                setItem: (key, value) => ls.set(key, value),
                removeItem: key => ls.remove(key)
            }
        })],
})


