import axios from 'axios'

const state = {
    drug_provider: {},
    drug_providers: {},
    drug_provider_errors: {},
    drug_provider_drugs: {},
    drug_provider_unattached_drugs: {},
    drug_provider_attached_drugs: {},
    drug_provider_files: {},
    drug_provider_arrivals: {},
    drug_providers_loading: false,
}

const getters = {
    drug_provider: state => state.drug_provider,
    drug_providers: state => state.drug_providers,
    drug_providers_loading: state => state.drug_providers_loading,
    drug_provider_errors: state => state.drug_provider_errors,
    drug_provider_drugs: state => state.drug_provider_drugs,
    drug_provider_unattached_drugs: state => state.drug_provider_unattached_drugs,
    drug_provider_attached_drugs: state => state.drug_provider_attached_drugs,
    drug_provider_files: state => state.drug_provider_files,
    drug_provider_arrivals: state => state.drug_provider_arrivals,
}

const mutations = {
    setDrugProvider(state, drug_provider) {
        state.drug_provider = drug_provider
    },
    setDrugProviders(state, drug_providers) {
        state.drug_providers = drug_providers
    },
    setDrugProviderDrugs(state, drug_provider_drugs) {
        state.drug_provider_drugs = drug_provider_drugs
    },
    setDrugProviderUnattachedDrugs(state, drug_provider_unattached_drugs) {
        state.drug_provider_unattached_drugs = drug_provider_unattached_drugs
    },
    setDrugProviderAttachedDrugs(state, drug_provider_attached_drugs) {
        state.drug_provider_attached_drugs = drug_provider_attached_drugs
    },
    setDrugProviderFiles(state, drug_provider_files) {
        state.drug_provider_files = drug_provider_files
    },
    setDrugProviderArrivals(state, drug_provider_arrivals) {
        state.drug_provider_arrivals = drug_provider_arrivals
    },
    setDrugProvidersLoading(state, drug_providers_loading) {
        state.drug_providers_loading = drug_providers_loading
    },
    setDrugProviderErrors(state, errors) {
        state.drug_provider_errors = errors
    },
}

const actions = {
    async getDrugProviders({commit, getters}, params) {
        if (getters.permissions?.includes('drug_providers.index')) {
            commit('setDrugProviderErrors', {})
            commit('setDrugProvidersLoading', true)
            try {
                let response = await axios.get('/api/drug-providers', {params})
                commit('setDrugProviders', response.data)
                commit('setDrugProvidersLoading', false)


            } catch (error) {
                commit('setDrugProviderErrors', error.response.data.errors)
                return false
            }
        }
    },
    async getDrugProvider({commit}, id) {
        commit('setDrugProviderErrors', {})
        commit('setDrugProvidersLoading', true)
        try {
            let response = await axios.get(`/api/drug-providers/${id}/show`)
            commit('setDrugProvider', response.data)
            commit('setDrugProvidersLoading', false)
        } catch (error) {
            commit('setDrugProviderErrors', error.response.data.errors)
            return false
        }
    },
    async getDrugProviderDrugs({commit, getters}, params) {
        if (getters.permissions?.includes('drug_providers.drugs')) {
            commit('setDrugProviderErrors', {})
            commit('setDrugProvidersLoading', true)
            try {
                let response = await axios.get(`/api/drug-providers/${params.id}/drugs`, {params})
                commit('setDrugProviderDrugs', response.data)
                commit('setDrugProvidersLoading', false)
            } catch (error) {
                commit('setDrugProviderErrors', error?.response?.data?.errors)
                return false
            }
        }
    },
    async getDrugProviderArrivals({commit}, params) {
        commit('setDrugProviderErrors', {})
        commit('setDrugProvidersLoading', true)
        try {
            let response = await axios.get(`/api/drug-providers/${params.id}/arrivals`, {params})
            commit('setDrugProviderArrivals', response.data)
            commit('setDrugProvidersLoading', false)
        } catch (error) {
            commit('setDrugProviderErrors', error?.response?.data?.errors)
            return false
        }
    },
    async getDrugProviderUnattachedDrugs({commit, getters}, params) {
        if (getters.permissions?.includes('drug_providers.attached_drugs.unattached')) {
            commit('setDrugProviderErrors', {})
            commit('setDrugProvidersLoading', true)
            try {
                let response = await axios.get(`/api/drug-providers/${params.id}/attached-drugs/unattached`, {params})
                console.log(response);
                commit('setDrugProviderUnattachedDrugs', response.data)
                commit('setDrugProvidersLoading', false)
            } catch (error) {
                commit('setDrugProviderErrors', error?.response?.data?.errors)
                return false
            }
        }
    },
    async getDrugProviderAttachedDrugs({commit, getters}, params) {
        if (getters.permissions?.includes('drug_providers.attached_drugs.attached')) {
            commit('setDrugProviderErrors', {})
            commit('setDrugProvidersLoading', true)
            try {
                let response = await axios.get(`/api/drug-providers/${params.id}/attached-drugs/attached`, {params})
                console.log(response);
                commit('setDrugProviderAttachedDrugs', response.data)
                commit('setDrugProvidersLoading', false)
            } catch (error) {
                commit('setDrugProviderErrors', error?.response?.data?.errors)
                return false
            }
        }
    },
    async getDrugProviderFiles({commit}, params) {
        commit('setDrugProviderErrors', {})
        commit('setDrugProvidersLoading', true)
        try {
            let response = await axios.get(`/api/drug-providers/${params.id}/files`, {params})
            commit('setDrugProviderFiles', response.data)
            commit('setDrugProvidersLoading', false)
        } catch (error) {
            commit('setDrugProviderErrors', error?.response?.data?.errors)
            return false
        }
    },
    async storeDrugProvider({commit}, data) {
        commit('setDrugProviderErrors', {})
        commit('setDrugProvidersLoading', true)
        try {
            let response = await axios.post('/api/drug-providers/store', data)
            commit('setDrugProvidersLoading', false)
            return response.data
        } catch (error) {
            commit('setDrugProviderErrors', error.response.data.errors)
            commit('setDrugProvidersLoading', false)

            return false
        }
    },
    async storeDrugProviderArrival({commit}, data) {
        commit('setDrugProviderErrors', {})
        commit('setDrugProvidersLoading', true)
        try {
            let response = await axios.post(`/api/drug-providers/${data.id}/arrivals/store`, data)
            commit('setDrugProvidersLoading', false)
            return response.data
        } catch (error) {
            commit('setDrugProviderErrors', error.response.data.errors)
            commit('setDrugProvidersLoading', false)

            return false
        }
    },

    async updateDrugProvider({commit}, data) {
        commit('setDrugProviderErrors', {})
        commit('setDrugProvidersLoading', true)
        try {
            let response = await axios.put(`/api/drug-providers/${data.id}/update`, data)
            commit('setDrugProvidersLoading', false)
            return response.data
        } catch (error) {
            commit('setDrugProviderErrors', error.response.data.errors)
            commit('setDrugProvidersLoading', false)
            return false
        }
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}