import axios from 'axios'

const state = {
    role: {},
    role_drugs: {},
    roles: {},
    role_errors: {},
    permissions_data: {},
    roles_loading: false,
}

const getters = {
    role: state => state.role,
    role_drugs: state => state.role_drugs,
    roles: state => state.roles,
    roles_loading: state => state.roles_loading,
    permissions_data: state => state.permissions_data,
    role_errors: state => state.role_errors,
}

const mutations = {
    setRole(state, role) {
        state.role = role
    },
    setRoleDrugs(state, role_drugs) {
        state.role_drugs = role_drugs
    },
    setRoles(state, roles) {
        state.roles = roles
    },
    setPermissionsData(state, permissions_data) {
        state.permissions_data = permissions_data
    },
    setRolesLoading(state, roles_loading) {
        state.roles_loading = roles_loading
    },
    setRoleErrors(state, errors) {
        state.role_errors = errors
    },
}

const actions = {
    async getRoles({commit}, params) {
        commit('setRoleErrors', {})
        commit('setRolesLoading', true)
        try {
            let response = await axios.get('/api/roles', {params})
            commit('setRoles', response.data)
            commit('setRolesLoading', false)


        } catch (error) {
            commit('setRoleErrors', error.response.data.errors)
            return false
        }
    },
    async getPermissions({commit}, params) {
        commit('setRoleErrors', {})
        commit('setRolesLoading', true)
        try {
            let response = await axios.get('/api/permissions', {params})
            commit('setPermissionsData', response.data)
            commit('setRolesLoading', false)


        } catch (error) {
            commit('setRoleErrors', error.response.data.errors)
            return false
        }
    },
    async getRole({commit}, id) {
        commit('setRoleErrors', {})
        commit('setRolesLoading', true)
        try {
            let response = await axios.get(`/api/roles/${id}/show`)
            commit('setRole', response.data)
            commit('setRolesLoading', false)
        } catch (error) {
            commit('setRoleErrors', error.response.data.errors)
            return false
        }
    },
    async getRoleDrugs({commit}, params) {
        commit('setRoleErrors', {})
        commit('setRolesLoading', true)
        try {
            let response = await axios.get(`/api/roles/${params.id}/drugs`, {params})
            commit('setRoleDrugs', response.data)
            commit('setRolesLoading', false)
        } catch (error) {
            commit('setRoleErrors', error.response.data.errors)
            return false
        }
    },
    async storeRole({commit}, data) {
        commit('setRoleErrors', {})
        commit('setRolesLoading', true)
        try {
            let response = await axios.post('/api/roles/store', data)
            commit('setRolesLoading', false)
            return response.data
        } catch (error) {
            commit('setRoleErrors', error.response.data.errors)
            commit('setRolesLoading', false)

            return false
        }
    },

    async updateRole({commit}, data) {
        commit('setRoleErrors', {})
        commit('setRolesLoading', true)
        try {
            let response = await axios.put(`/api/roles/${data.id}/update`, data)
            commit('setRolesLoading', false)
            return response.data
        } catch (error) {
            commit('setRoleErrors', error.response.data.errors)
            commit('setRolesLoading', false)
            return false
        }
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}