const routes = [
    {
        path: '/',
        name: 'index',
        component: () => import('@/pages/Index/IndexPage.vue'),
        meta: {
            requires_auth: true,
            layout: 'content',
            title: 'Bosh sahifa',
        }
    },
    {
        path: '/profile',
        name: 'index',
        component: () => import('@/pages/Profile/IndexPage.vue'),
        meta: {
            requires_auth: true,
            layout: 'content',
            title: "Shaxsiy ma'lumotlar",
        }
    },
    {
        path: '/statistics',
        name: 'index',
        component: () => import('@/pages/Statistics/IndexPage.vue'),
        meta: {
            requires_auth: true,
            layout: 'content',
            title: "Statistika",
        }
    },
    {
        path: '/users',
        name: 'users.index',
        component: () => import('@/pages/User/IndexPage.vue'),
        meta: {
            requires_auth: true,
            layout: 'content',
            title: 'Bosh sahifa',
        }
    },
    {
        path: '/users/create',
        name: 'users.create',
        component: () => import('@/pages/User/CreatePage.vue'),
        meta: {
            requires_auth: true,
            layout: 'content',
            title: 'Bosh sahifa',
        }
    },
    {
        path: '/users/:id/edit',
        name: 'users.edit',
        component: () => import('@/pages/User/EditPage.vue'),
        meta: {
            requires_auth: true,
            layout: 'content',
            title: 'Bosh sahifa',
        }
    },
    {
        path: '/roles',
        name: 'roles.index',
        component: () => import('@/pages/Role/IndexPage.vue'),
        meta: {
            requires_auth: true,
            layout: 'content',
            title: 'Bosh sahifa',
        }
    },
    {
        path: '/branch-drugs',
        name: 'branch_drugs.index',
        component: () => import('@/pages/BranchDrug/IndexPage.vue'),
        meta: {
            requires_auth: true,
            layout: 'content',
            title: 'Bosh sahifa',
        }
    },
    {
        path: '/attach-drugs',
        name: 'attach_drugs.index',
        component: () => import('@/pages/AttachDrug/IndexPage.vue'),
        meta: {
            requires_auth: true,
            layout: 'content',
            title: 'Bosh sahifa',
        }
    },
    {
        path: '/roles/create',
        name: 'roles.create',
        component: () => import('@/pages/Role/CreatePage.vue'),
        meta: {
            requires_auth: true,
            layout: 'content',
            title: 'Bosh sahifa',
        }
    },
    {
        path: '/roles/:id/edit',
        name: 'roles.edit',
        component: () => import('@/pages/Role/EditPage.vue'),
        meta: {
            requires_auth: true,
            layout: 'content',
            title: 'Bosh sahifa',
        }
    },
    {
        path: '/drugs',
        name: 'drugs.index',
        component: () => import('@/pages/Drug/IndexPage.vue'),
        meta: {
            requires_auth: true,
            layout: 'content',
            title: 'Bosh sahifa',
        }
    },
    {
        path: '/drugs/create',
        name: 'drugs.create',
        component: () => import('@/pages/Drug/CreatePage.vue'),
        meta: {
            requires_auth: true,
            layout: 'content',
            title: 'Bosh sahifa',
        }
    },
    {
        path: '/drugs/:id/edit',
        name: 'drugs.edit',
        component: () => import('@/pages/Drug/EditPage.vue'),
        meta: {
            requires_auth: true,
            layout: 'content',
            title: 'Bosh sahifa',
        }
    },
    {
        path: '/families',
        name: 'families.index',
        component: () => import('@/pages/Family/IndexPage.vue'),
        meta: {
            requires_auth: true,
            layout: 'content',
            title: 'Bosh sahifa',
        }
    },
    {
        path: '/families/:id/edit',
        name: 'families.edit',
        component: () => import('@/pages/Family/EditPage.vue'),
        meta: {
            requires_auth: true,
            layout: 'content',
            title: 'Bosh sahifa',
        }
    },
    {
        path: '/applications',
        name: 'applications.index',
        component: () => import('@/pages/Application/IndexPage.vue'),
        meta: {
            requires_auth: true,
            layout: 'content',
            title: 'Bosh sahifa',
        }
    },
    {
        path: '/applications/create',
        name: 'applications.create',
        component: () => import('@/pages/Application/CreatePage.vue'),
        meta: {
            requires_auth: true,
            layout: 'content',
            title: 'Bosh sahifa',
        }
    },
    {
        path: '/applications/:id/edit',
        name: 'applications.edit',
        component: () => import('@/pages/Application/EditPage.vue'),
        meta: {
            requires_auth: true,
            layout: 'content',
            title: 'Bosh sahifa',
        }
    },
    {
        path: '/arrivals',
        name: 'arrivals.index',
        component: () => import('@/pages/Arrival/IndexPage.vue'),
        meta: {
            requires_auth: true,
            layout: 'content',
            title: 'Bosh sahifa',
        }
    },
    {
        path: '/arrivals/create',
        name: 'arrivals.create',
        component: () => import('@/pages/Arrival/CreatePage.vue'),
        meta: {
            requires_auth: true,
            layout: 'content',
            title: 'Bosh sahifa',
        }
    },
    {
        path: '/arrivals/:id/edit',
        name: 'arrivals.edit',
        component: () => import('@/pages/Arrival/EditPage.vue'),
        meta: {
            requires_auth: true,
            layout: 'content',
            title: 'Bosh sahifa',
        }
    },
    {
        path: '/branch-arrivals',
        name: 'branch_arrivals.index',
        component: () => import('@/pages/BranchArrival/IndexPage.vue'),
        meta: {
            requires_auth: true,
            layout: 'content',
            title: 'Bosh sahifa',
        }
    },
    {
        path: '/branch-arrivals/create',
        name: 'branch_arrivals.create',
        component: () => import('@/pages/BranchArrival/CreatePage.vue'),
        meta: {
            requires_auth: true,
            layout: 'content',
            title: 'Bosh sahifa',
        }
    },
    {
        path: '/branch-arrivals/:id/edit',
        name: 'branch_arrivals.edit',
        component: () => import('@/pages/BranchArrival/EditPage.vue'),
        meta: {
            requires_auth: true,
            layout: 'content',
            title: 'Bosh sahifa',
        }
    },
    {
        path: '/branch-outcomes',
        name: 'branch_outcomes.index',
        component: () => import('@/pages/BranchOutcome/IndexPage.vue'),
        meta: {
            requires_auth: true,
            layout: 'content',
            title: 'Bosh sahifa',
        }
    },
    {
        path: '/branch-outcomes/create',
        name: 'branch_outcomes.create',
        component: () => import('@/pages/BranchOutcome/CreatePage.vue'),
        meta: {
            requires_auth: true,
            layout: 'content',
            title: 'Bosh sahifa',
        }
    },
    {
        path: '/branch-outcomes/:id/edit',
        name: 'branch_outcomes.edit',
        component: () => import('@/pages/BranchOutcome/EditPage.vue'),
        meta: {
            requires_auth: true,
            layout: 'content',
            title: 'Bosh sahifa',
        }
    },
    {
        path: '/acts',
        name: 'acts.index',
        component: () => import('@/pages/Act/IndexPage.vue'),
        meta: {
            requires_auth: true,
            layout: 'content',
            title: 'Bosh sahifa',
        }
    },
    {
        path: '/acts/create',
        name: 'acts.create',
        component: () => import('@/pages/Act/CreatePage.vue'),
        meta: {
            requires_auth: true,
            layout: 'content',
            title: 'Bosh sahifa',
        }
    },
    {
        path: '/acts/:id/edit',
        name: 'acts.edit',
        component: () => import('@/pages/Act/EditPage.vue'),
        meta: {
            requires_auth: true,
            layout: 'content',
            title: 'Bosh sahifa',
        }
    },
    {
        path: '/sales',
        name: 'sales.index',
        component: () => import('@/pages/Sale/IndexPage.vue'),
        meta: {
            requires_auth: true,
            layout: 'content',
            title: 'Bosh sahifa',
        }
    },
    {
        path: '/sales-all',
        name: 'sales_all.index',
        component: () => import('@/pages/Sale/AllPage.vue'),
        meta: {
            requires_auth: true,
            layout: 'content',
            title: 'Bosh sahifa',
        }
    },
    // {
    //     path: '/sales/create',
    //     name: 'sales.create',
    //     component: () => import('@/pages/Sale/CreatePage.vue'),
    //     meta: {
    //         requires_auth: true,
    //         layout: 'content',
    //         title: 'Bosh sahifa',
    //     }
    // },
    // {
    //     path: '/sales/:id/edit',
    //     name: 'sales.edit',
    //     component: () => import('@/pages/Sale/EditPage.vue'),
    //     meta: {
    //         requires_auth: true,
    //         layout: 'content',
    //         title: 'Bosh sahifa',
    //     }
    // },
    {
        path: '/orders',
        name: 'orders.index',
        component: () => import('@/pages/Order/IndexPage.vue'),
        meta: {
            requires_auth: true,
            layout: 'content',
            title: 'Bosh sahifa',
        }
    },
    {
        path: '/orders/create',
        name: 'orders.create',
        component: () => import('@/pages/Order/CreatePage.vue'),
        meta: {
            requires_auth: true,
            layout: 'content',
            title: 'Bosh sahifa',
        }
    },
    {
        path: '/orders/:id/edit',
        name: 'orders.edit',
        component: () => import('@/pages/Order/EditPage.vue'),
        meta: {
            requires_auth: true,
            layout: 'content',
            title: 'Bosh sahifa',
        }
    },
    {
        path: '/warehouses',
        name: 'warehouses.index',
        component: () => import('@/pages/Warehouse/IndexPage.vue'),
        meta: {
            requires_auth: true,
            layout: 'content',
            title: 'Bosh sahifa',
        }
    },
    {
        path: '/warehouses/create',
        name: 'warehouses.create',
        component: () => import('@/pages/Warehouse/CreatePage.vue'),
        meta: {
            requires_auth: true,
            layout: 'content',
            title: 'Bosh sahifa',
        }
    },
    {
        path: '/warehouses/:id/edit',
        name: 'warehouses.edit',
        component: () => import('@/pages/Warehouse/EditPage.vue'),
        meta: {
            requires_auth: true,
            layout: 'content',
            title: 'Bosh sahifa',
        }
    },
    {
        path: '/branch-groups',
        name: 'branch_groups.index',
        component: () => import('@/pages/BranchGroup/IndexPage.vue'),
        meta: {
            requires_auth: true,
            layout: 'content',
            title: 'Bosh sahifa',
        }
    },
    {
        path: '/branch-groups/create',
        name: 'branch_groups.create',
        component: () => import('@/pages/BranchGroup/CreatePage.vue'),
        meta: {
            requires_auth: true,
            layout: 'content',
            title: 'Bosh sahifa',
        }
    },
    {
        path: '/branch-groups/:id/edit',
        name: 'branch_groups.edit',
        component: () => import('@/pages/BranchGroup/EditPage.vue'),
        meta: {
            requires_auth: true,
            layout: 'content',
            title: 'Bosh sahifa',
        }
    },
    {
        path: '/drug-providers',
        name: 'drug_providers.index',
        component: () => import('@/pages/DrugProvider/IndexPage.vue'),
        meta: {
            requires_auth: true,
            layout: 'content',
            title: 'Bosh sahifa',
        }
    },
    {
        path: '/drug-providers/create',
        name: 'drug_providers.create',
        component: () => import('@/pages/DrugProvider/CreatePage.vue'),
        meta: {
            requires_auth: true,
            layout: 'content',
            title: 'Bosh sahifa',
        }
    },
    {
        path: '/drug-providers/:id/edit',
        name: 'drug_providers.edit',
        component: () => import('@/pages/DrugProvider/EditPage.vue'),
        meta: {
            requires_auth: true,
            layout: 'content',
            title: 'Bosh sahifa',
        }
    },

    {
        path: '/branches',
        name: 'branches.index',
        component: () => import('@/pages/Branch/IndexPage.vue'),
        meta: {
            requires_auth: true,
            layout: 'content',
            title: 'Bosh sahifa',
        }
    },
    {
        path: '/branches/create',
        name: 'branches.create',
        component: () => import('@/pages/Branch/CreatePage.vue'),
        meta: {
            requires_auth: true,
            layout: 'content',
            title: 'Bosh sahifa',
        }
    },
    {
        path: '/branches/:id/edit',
        name: 'branches.edit',
        component: () => import('@/pages/Branch/EditPage.vue'),
        meta: {
            requires_auth: true,
            layout: 'content',
            title: 'Bosh sahifa',
        }
    },
    {
        path: '/impersonate',
        name: 'impersonate',
        component: () => import('@/pages/Index/ImpersonatePage.vue'),
        meta: {
            requires_auth: false,
            layout: 'blank',
            title: 'Bosh sahifa',
        }
    },
    {
        path: "*",
        component: () => import('@/pages/Page/PageNotFound.vue'),
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/pages/Admin/LoginPage.vue'),
        meta: {
            requires_auth: false,
            layout: 'register',
            title: 'Kirish',
        }
    },
    {
        path: '/not-auth',
        name: 'not_auth',
        meta: {
            layout: 'content',
            requires_auth: false,
            title: '401',
        },
        component: () => import('@/pages/Page/PageNotAllowed.vue'),
    },
    {
        path: '/privacy-policy',
        name: 'privacy_policy',
        component: () => import('@/pages/Page/PrivacyPolicyPage.vue'),
        meta: {
            requires_auth: false,
            layout: 'blank',
            title: 'Maxfiylik siyosati',
        }
    },


]

export default routes