import axios from 'axios'

const state = {
    application: {},
    applications: {},
    application_errors: {},
    application_drugs: {},
    applications_loading: false,
}

const getters = {
    application: state => state.application,
    applications: state => state.applications,
    applications_loading: state => state.applications_loading,
    application_errors: state => state.application_errors,
    application_drugs: state => state.application_drugs,
}

const mutations = {
    setApplication(state, application) {
        state.application = application
    },
    setApplicationDrugs(state, application_drugs) {
        state.application_drugs = application_drugs
    },
    setApplications(state, applications) {
        state.applications = applications
    },
    setApplicationsLoading(state, applications_loading) {
        state.applications_loading = applications_loading
    },
    setApplicationErrors(state, errors) {
        state.application_errors = errors
    },
}

const actions = {
    async getApplications({commit, getters}, params) {
        if (getters.permissions?.includes('applications.index')) {
            commit('setApplicationErrors', {})
            commit('setApplicationsLoading', true)
            try {
                let response = await axios.get('/api/applications', {params})
                commit('setApplications', response.data)
                commit('setApplicationsLoading', false)


            } catch (error) {
                commit('setApplicationErrors', error.response.data.errors)
                return false
            }
        }
    },
    async getApplication({commit}, id) {
        commit('setApplicationErrors', {})
        commit('setApplicationsLoading', true)
        try {
            let response = await axios.get(`/api/applications/${id}/show`)
            commit('setApplication', response.data)
            commit('setApplicationsLoading', false)
        } catch (error) {
            commit('setApplicationErrors', error.response.data.errors)
            return false
        }
    },
    async getApplicationDrugs({commit}, params) {
        commit('setApplicationErrors', {})
        commit('setApplicationsLoading', true)
        try {
            let response = await axios.get(`/api/applications/${params.id}/drugs`, {params})
            commit('setApplicationDrugs', response.data)
            commit('setApplicationsLoading', false)
        } catch (error) {
            commit('setApplicationErrors', error.response.data.errors)
            return false
        }
    },
    async storeApplication({commit}, data) {
        commit('setApplicationErrors', {})
        commit('setApplicationsLoading', true)
        try {
            let response = await axios.post('/api/applications/store', data)
            commit('setApplicationsLoading', false)
            return response.data
        } catch (error) {
            commit('setApplicationErrors', error.response.data.errors)
            commit('setApplicationsLoading', false)

            return error.response.data
        }
    },

    async updateApplication({commit}, data) {
        commit('setApplicationErrors', {})
        commit('setApplicationsLoading', true)
        try {
            let response = await axios.put(`/api/applications/${data.id}/update`, data)
            commit('setApplicationsLoading', false)
            return response.data
        } catch (error) {
            commit('setApplicationErrors', error.response.data.errors)
            commit('setApplicationsLoading', false)
            return false
        }
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}