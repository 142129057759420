import axios from 'axios'

const state = {
    family: {},
    families: {},
    family_errors: {},
    family_drugs: {},
    families_loading: false,
}

const getters = {
    family: state => state.family,
    families: state => state.families,
    families_loading: state => state.families_loading,
    family_errors: state => state.family_errors,
    family_drugs: state => state.family_drugs,
}

const mutations = {
    setFamily(state, family) {
        state.family = family
    },
    setFamilyDrugs(state, family_drugs) {
        state.family_drugs = family_drugs
    },
    setFamilies(state, families) {
        state.families = families
    },
    setFamiliesLoading(state, families_loading) {
        state.families_loading = families_loading
    },
    setFamilyErrors(state, errors) {
        state.family_errors = errors
    },
}

const actions = {
    async getFamilies({commit, getters}, params) {
        if (getters.permissions?.includes('families.index')) {
            commit('setFamilyErrors', {})
            commit('setFamiliesLoading', true)
            try {
                let response = await axios.get('/api/families', {params})
                commit('setFamilies', response.data)
                commit('setFamiliesLoading', false)


            } catch (error) {
                commit('setFamilyErrors', error.response.data.errors)
                return false
            }
        }
    },
    async getFamily({commit}, id) {
        commit('setFamilyErrors', {})
        commit('setFamiliesLoading', true)
        try {
            let response = await axios.get(`/api/families/${id}/show`)
            commit('setFamily', response.data)
            commit('setFamiliesLoading', false)
        } catch (error) {
            commit('setFamilyErrors', error.response.data.errors)
            return false
        }
    },
    async getFamilyDrugs({commit, getters}, params) {
        if (getters.permissions?.includes('families.drugs')) {
            commit('setFamilyErrors', {})
            commit('setFamiliesLoading', true)
            try {
                let response = await axios.get(`/api/families/${params.id}/drugs`, {params})
                commit('setFamilyDrugs', response.data)
                commit('setFamiliesLoading', false)
            } catch (error) {
                commit('setFamilyErrors', error.response.data.errors)
                return false
            }
        }
    },
    async storeFamily({commit}, data) {
        commit('setFamilyErrors', {})
        commit('setFamiliesLoading', true)
        try {
            let response = await axios.post('/api/families/store', data)
            commit('setFamiliesLoading', false)
            return response.data
        } catch (error) {
            commit('setFamilyErrors', error.response.data.errors)
            commit('setFamiliesLoading', false)

            return false
        }
    },

    async updateFamily({commit}, data) {
        commit('setFamilyErrors', {})
        commit('setFamiliesLoading', true)
        try {
            let response = await axios.put(`/api/families/${data.id}/update`, data)
            commit('setFamiliesLoading', false)
            return response.data
        } catch (error) {
            commit('setFamilyErrors', error.response.data.errors)
            commit('setFamiliesLoading', false)
            return false
        }
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}