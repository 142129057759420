import axios from 'axios'

const state = {
    act: {},
    acts: {},
    act_errors: {},
    act_drugs: {},
    acts_loading: false,
}

const getters = {
    act: state => state.act,
    acts: state => state.acts,
    acts_loading: state => state.acts_loading,
    act_errors: state => state.act_errors,
    act_drugs: state => state.act_drugs,
}

const mutations = {
    setAct(state, act) {
        state.act = act
    },
    setActDrugs(state, act_drugs) {
        state.act_drugs = act_drugs
    },
    setActs(state, acts) {
        state.acts = acts
    },
    setActsLoading(state, acts_loading) {
        state.acts_loading = acts_loading
    },
    setActErrors(state, errors) {
        state.act_errors = errors
    },
}

const actions = {
    async getActs({commit}, params) {
        commit('setActErrors', {})
        commit('setActsLoading', true)
        try {
            let response = await axios.get('/api/acts', {params})
            commit('setActs', response.data)
            commit('setActsLoading', false)


        } catch (error) {
            commit('setActErrors', error.response.data.errors)
            return false
        }
    },
    async getAct({commit}, id) {
        commit('setActErrors', {})
        commit('setActsLoading', true)
        try {
            let response = await axios.get(`/api/acts/${id}/show`)
            commit('setAct', response.data)
            commit('setActsLoading', false)
        } catch (error) {
            commit('setActErrors', error.response.data.errors)
            return false
        }
    },
    async getActDrugs({commit}, params) {
        commit('setActErrors', {})
        commit('setActsLoading', true)
        try {
            let response = await axios.get(`/api/acts/${params.id}/drugs`, {params})
            commit('setActDrugs', response.data)
            commit('setActsLoading', false)
        } catch (error) {
            commit('setActErrors', error.response.data.errors)
            return false
        }
    },
    async storeAct({commit}, data) {
        commit('setActErrors', {})
        commit('setActsLoading', true)
        try {
            let response = await axios.post('/api/acts/store', data)
            commit('setActsLoading', false)
            return response.data
        } catch (error) {
            commit('setActErrors', error.response.data.errors)
            commit('setActsLoading', false)

            return false
        }
    },

    async updateAct({commit}, data) {
        commit('setActErrors', {})
        commit('setActsLoading', true)
        try {
            let response = await axios.put(`/api/acts/${data.id}/update`, data)
            commit('setActsLoading', false)
            return response.data
        } catch (error) {
            commit('setActErrors', error.response.data.errors)
            commit('setActsLoading', false)
            return false
        }
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}