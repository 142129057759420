import axios from 'axios'

const state = {
    order: {},
    orders: {},
    order_errors: {},
    order_drugs: {},
    order_grouped_drugs: {},
    order_grouped_provider_drugs: {},
    orders_loading: false,
}

const getters = {
    order: state => state.order,
    orders: state => state.orders,
    orders_loading: state => state.orders_loading,
    order_grouped_drugs: state => state.order_grouped_drugs,
    order_errors: state => state.order_errors,
    order_grouped_provider_drugs: state => state.order_grouped_provider_drugs,
    order_drugs: state => state.order_drugs,
}

const mutations = {
    setOrder(state, order) {
        state.order = order
    },
    setOrderDrugs(state, order_drugs) {
        state.order_drugs = order_drugs
    },
    setOrderGroupDrugs(state, order_grouped_drugs) {
        state.order_grouped_drugs = order_grouped_drugs
    },
    setOrderGroupProviderDrugs(state, order_grouped_provider_drugs) {
        state.order_grouped_provider_drugs = order_grouped_provider_drugs
    },
    setOrders(state, orders) {
        state.orders = orders
    },
    setOrdersLoading(state, orders_loading) {
        state.orders_loading = orders_loading
    },
    setOrderErrors(state, errors) {
        state.order_errors = errors
    },
}

const actions = {
    async getOrders({commit, getters}, params) {
        if (getters.permissions?.includes('orders.index')) {
            commit('setOrderErrors', {})
            commit('setOrdersLoading', true)
            try {
                let response = await axios.get('/api/orders', {params})
                commit('setOrders', response.data)
                commit('setOrdersLoading', false)


            } catch (error) {
                commit('setOrderErrors', error.response.data.errors)
                return false
            }
        }
    },
    async getOrder({commit}, id) {
        commit('setOrderErrors', {})
        commit('setOrdersLoading', true)
        try {
            let response = await axios.get(`/api/orders/${id}/show`)
            commit('setOrder', response.data)
            commit('setOrdersLoading', false)
        } catch (error) {
            commit('setOrderErrors', error.response.data.errors)
            return false
        }
    },
    async getOrderGroupedDrugs({commit}, params) {
        commit('setOrderErrors', {})
        commit('setOrdersLoading', true)
        try {
            let response = await axios.get(`/api/drugs/grouped`, {params})
            commit('setOrderGroupDrugs', response.data)
            commit('setOrdersLoading', false)
        } catch (error) {
            commit('setOrderErrors', error.response.data.errors)
            return false
        }
    },
    async updateOrderDrug({commit}, data) {
        commit('setOrderDrugErrors', {})
        commit('setOrderDrugsLoading', true)
        try {
            let response = await axios.put(`/api/order-drugs/${data.id}/update`, data)
            commit('setOrderDrugsLoading', false)
            return response.data
        } catch (error) {
            commit('setOrderDrugErrors', error.response.data.errors)
            commit('setOrderDrugsLoading', false)
            return false
        }
    },
    async getOrderGroupedProviderDrugs({commit}, params) {
        commit('setOrderErrors', {})
        commit('setOrdersLoading', true)
        try {
            let response = await axios.get(`/api/drugs/${params.id}/grouped-drug-providers`, {params})
            commit('setOrderGroupProviderDrugs', response.data)
            commit('setOrdersLoading', false)
        } catch (error) {
            commit('setOrderErrors', error.response.data.errors)
            return false
        }
    },
    async getOrderDrugs({commit}, params) {
        commit('setOrderErrors', {})
        commit('setOrdersLoading', true)
        try {
            let response = await axios.get(`/api/orders/${params.id}/drugs`, {params})
            commit('setOrderDrugs', response.data)
            commit('setOrdersLoading', false)
        } catch (error) {
            commit('setOrderErrors', error.response.data.errors)
            return false
        }
    },
    async storeOrder({commit}, data) {
        commit('setOrderErrors', {})
        commit('setOrdersLoading', true)
        try {
            let response = await axios.post('/api/orders/store', data)
            commit('setOrdersLoading', false)
            return response.data
        } catch (error) {
            commit('setOrderErrors', error.response.data.errors)
            commit('setOrdersLoading', false)

            return false
        }
    },

    async updateOrder({commit}, data) {
        commit('setOrderErrors', {})
        commit('setOrdersLoading', true)
        try {
            let response = await axios.put(`/api/orders/${data.id}/update`, data)
            commit('setOrdersLoading', false)
            return response.data
        } catch (error) {
            commit('setOrderErrors', error.response.data.errors)
            commit('setOrdersLoading', false)
            return false
        }
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}