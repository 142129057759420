import axios from 'axios'

const state = {
    arrival: {},
    arrivals: {},
    arrival_errors: {},
    arrival_drugs: {},
    arrivals_loading: false,
}

const getters = {
    arrival: state => state.arrival,
    arrivals: state => state.arrivals,
    arrivals_loading: state => state.arrivals_loading,
    arrival_errors: state => state.arrival_errors,
    arrival_drugs: state => state.arrival_drugs,
}

const mutations = {
    setArrival(state, arrival) {
        state.arrival = arrival
    },
    setArrivalDrugs(state, arrival_drugs) {
        state.arrival_drugs = arrival_drugs
    },
    setArrivals(state, arrivals) {
        state.arrivals = arrivals
    },
    setArrivalsLoading(state, arrivals_loading) {
        state.arrivals_loading = arrivals_loading
    },
    setArrivalErrors(state, errors) {
        state.arrival_errors = errors
    },
}

const actions = {
    async getArrivals({commit, getters}, params) {
        if (getters.permissions?.includes('arrivals.index')) {
            commit('setArrivalErrors', {})
            commit('setArrivalsLoading', true)
            try {
                let response = await axios.get('/api/arrivals', {params})
                commit('setArrivals', response.data)
                commit('setArrivalsLoading', false)


            } catch (error) {
                commit('setArrivalErrors', error.response.data.errors)
                return false
            }
        }
    },
    async getArrival({commit}, id) {
        commit('setArrivalErrors', {})
        commit('setArrivalsLoading', true)
        try {
            let response = await axios.get(`/api/arrivals/${id}/show`)
            commit('setArrival', response.data)
            commit('setArrivalsLoading', false)
        } catch (error) {
            commit('setArrivalErrors', error.response.data.errors)
            return false
        }
    },
    async getArrivalDrugs({commit, getters}, params) {
        if (getters.permissions?.includes('arrivals.drugs')) {
            commit('setArrivalErrors', {})
            commit('setArrivalsLoading', true)
            try {
                let response = await axios.get(`/api/arrivals/${params.id}/drugs`, {params})
                commit('setArrivalDrugs', response.data)
                commit('setArrivalsLoading', false)
            } catch (error) {
                commit('setArrivalErrors', error.response.data.errors)
                return false
            }
        }
    },
    async storeArrival({commit}, data) {
        commit('setArrivalErrors', {})
        commit('setArrivalsLoading', true)
        try {
            let response = await axios.post('/api/arrivals/store', data)
            commit('setArrivalsLoading', false)
            return response.data
        } catch (error) {
            commit('setArrivalErrors', error.response.data.errors)
            commit('setArrivalsLoading', false)

            return false
        }
    },

    async updateArrival({commit}, data) {
        commit('setArrivalErrors', {})
        commit('setArrivalsLoading', true)
        try {
            let response = await axios.put(`/api/arrivals/${data.id}/update`, data)
            commit('setArrivalsLoading', false)
            return response.data
        } catch (error) {
            commit('setArrivalErrors', error.response.data.errors)
            commit('setArrivalsLoading', false)
            return false
        }
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}