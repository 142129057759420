import axios from 'axios'

const state = {
    drug: {},
    drugs: {},
    prices: [],
    drug_errors: {},
    drugs_loading: false,
}

const getters = {
    drug: state => state.drug,
    drugs: state => state.drugs,
    prices: state => state.prices,
    drugs_loading: state => state.drugs_loading,
    prices_loading: state => state.prices_loading,
    drug_errors: state => state.drug_errors,
}

const mutations = {
    setDrug(state, drug) {
        state.drug = drug
    },
    setDrugs(state, drugs) {
        state.drugs = drugs
    },
    setPrices(state, prices) {
        state.prices = prices
    },
    setPricesLoading(state, prices_loading) {
        state.prices_loading = prices_loading
    },
    setDrugsLoading(state, drugs_loading) {
        state.drugs_loading = drugs_loading
    },
    setDrugErrors(state, errors) {
        state.drug_errors = errors
    },
}

const actions = {
    async getDrugs({commit, getters}, params) {
        if (getters.permissions?.includes('drugs.index')) {
            commit('setDrugErrors', {})
            commit('setDrugsLoading', true)
            try {
                let response = await axios.get('/api/drugs', {params})
                commit('setDrugs', response.data)
                commit('setDrugsLoading', false)


            } catch (error) {
                commit('setDrugErrors', error.response.data.errors)
                return false
            }
        }
    },
    async getUnattachedDrugs({commit, getters}, params) {
        if (getters.permissions?.includes('drugs.index')) {
            commit('setDrugErrors', {})
            commit('setDrugsLoading', true)
            try {
                let response = await axios.get('/api/drugs/unattached', {params})
                commit('setDrugProviderUnattachedDrugs', response.data)
                commit('setDrugsLoading', false)


            } catch (error) {
                commit('setDrugErrors', error.response.data.errors)
                return false
            }
        }
    },
    async getAttachedDrugs({commit, getters}, params) {
        if (getters.permissions?.includes('drugs.index')) {
            commit('setDrugErrors', {})
            commit('setDrugsLoading', true)
            try {
                let response = await axios.get('/api/drugs/attached', {params})
                commit('setDrugProviderAttachedDrugs', response.data)
                commit('setDrugsLoading', false)


            } catch (error) {
                commit('setDrugErrors', error.response.data.errors)
                return false
            }
        }
    },
    async getDrug({commit}, id) {
        commit('setDrugErrors', {})
        commit('setDrugsLoading', true)
        try {
            let response = await axios.get(`/api/drugs/${id}/show`)
            commit('setDrug', response.data)
            commit('setDrugsLoading', false)
        } catch (error) {
            commit('setDrugErrors', error.response.data.errors)
            return false
        }
    },
    async getDrugPrices({commit}, id) {
        commit('setDrugErrors', {})
        commit('setPricesLoading', true)
        try {
            let response = await axios.get(`/api/drugs/${id}/prices`)
            commit('setPrices', response.data?.data)
            commit('setPricesLoading', false)
        } catch (error) {
            commit('setDrugErrors', error.response.data.errors)
            return false
        }
    },
    async storeDrug({commit}, data) {
        commit('setDrugErrors', {})
        commit('setDrugsLoading', true)
        try {
            let response = await axios.post('/api/drugs/store', data)
            commit('setDrugsLoading', false)
            return response.data
        } catch (error) {
            commit('setDrugErrors', error.response.data.errors)
            commit('setDrugsLoading', false)

            return false
        }
    },

    async updateDrug({commit}, data) {
        commit('setDrugErrors', {})
        commit('setDrugsLoading', true)
        try {
            let response = await axios.put(`/api/drugs/${data.id}/update`, data)
            commit('setDrugsLoading', false)
            return response.data
        } catch (error) {
            commit('setDrugErrors', error.response.data.errors)
            commit('setDrugsLoading', false)
            return false
        }
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}