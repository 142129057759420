<template>
  <div>
    <v-navigation-drawer
        v-model="drawer"
        app
        clipped
        class="elevation-0"
        :width="expanded ? 300 : 70"
        color="white"
        elevation="0"
        style="border-right: 1px solid #e0e0e0"
    >
      <v-list dense nav class="mt-10">
        <v-list-item-group
            color="primary"
            v-model="navigations"
        >
          <template v-for="(item, index) in items">
            <v-list-item
                class="py-2 px-4 ma-0"
                :key="index"
                link
                :to="item.route"
                v-if="permissions.includes(item.permission)"
                :value="item.navigation"
            >
              <v-list-item-icon>
                <v-icon size="24">{{ item.icon }}</v-icon>
              </v-list-item-icon>


              <v-list-item-content>
                <v-list-item-title style="font-size: 16px">{{ item.title }}</v-list-item-title>
              </v-list-item-content>
              <v-chip
                  color="primary"
                  outlined

                  v-if="item.navigation == 'acts' && acts_count"
              >
                {{ acts_count }}
              </v-chip>
            </v-list-item>
          </template>
          <v-list-item
              class="py-2 px-4 ma-0"
              link
              value="logout"
              @click="logout_dialog = true"
          >
            <v-list-item-icon>
              <v-icon size="24">mdi-logout</v-icon>
            </v-list-item-icon>

            <v-list-item-content v-if="$route.name">
              <v-list-item-title style="font-size: 16px">{{ $t('basics.logout') }}</v-list-item-title>
            </v-list-item-content>

          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-dialog width="500" v-model="logout_dialog">
      <v-card elevation="0" rounded="lg">
        <v-card-title>{{ $t('basics.logout') }}</v-card-title>
        <v-card-text>
          {{ $t('basics.logout_confirm') }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="secondary" @click="logout_dialog = false" elevation="0">{{
              $t('basics.no')
            }}
          </v-btn>
          <v-btn color="error" elevation="0" @click="logout">{{ $t('basics.yes') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "SideBar",
  props: {
    expanded: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      drawer: true,
      acts_count: 0,
      logout_dialog: false,
      navigations: null,
    };
  },
  mounted() {
    this.actsCount()
  },
  watch: {
    async "$route.name"() {
      this.actsCount()
    }
  },
  computed: {
    items() {
      return [
        {
          title: this.$t('components.sidebar.profile'),
          icon: "mdi-account-circle",
          route: `/profile`,
          navigation: 'dashboard',
          permission: "view_profile"
        },
        {
          title: this.$t('components.sidebar.dashboard'),
          icon: "mdi-view-dashboard",
          route: `/statistics`,
          navigation: 'dashboard',
          permission: "view_dashboard"
        },
        {
          title: this.$t('components.sidebar.drug_providers'),
          icon: "mdi-truck-fast",
          route: `/drug-providers`,
          navigation: 'dashboard',
          permission: "view_drug_providers"
        },
        {
          title: this.$t('components.sidebar.attach_drugs'),
          icon: "mdi-attachment-plus",
          route: `/attach-drugs`,
          navigation: 'branch_drugs',
          permission: "view_drug_providers"
        },
        {
          title: this.$t('components.sidebar.orders'),
          icon: "mdi-ambulance",
          route: `/orders`,
          navigation: 'orders',
          permission: "view_orders"
        },
        {
          title: this.$t('components.sidebar.arrivals'),
          icon: "mdi-receipt-text",
          route: `/arrivals`,
          navigation: 'arrivals',
          permission: "view_arrivals"
        },
        {
          title: this.$t('components.sidebar.branch_arrivals'),
          icon: "mdi-receipt-text",
          route: `/branch-arrivals`,
          navigation: 'arrivals',
          permission: "view_branch_arrivals"
        },
        {
          title: this.$t('components.sidebar.sales'),
          icon: "mdi-cart-arrow-up",
          route: `/sales`,
          navigation: 'orders',
          permission: "view_sales"
        },
        {
          title: this.$t('components.sidebar.sales_all'),
          icon: "mdi-cart-arrow-up",
          route: `/sales-all`,
          navigation: 'orders',
          permission: "view_sales"
        },
        {
          title: this.$t('components.sidebar.acts'),
          icon: "mdi-receipt-text",
          route: `/acts`,
          navigation: 'acts',
          permission: "view_acts"
        },
        {
          title: this.$t('components.sidebar.branch_outcomes'),
          icon: "mdi-receipt-text",
          route: `/branch-outcomes`,
          navigation: 'arrivals',
          permission: "view_branch_outcomes"
        },
        {
          title: this.$t('components.sidebar.branch_drugs'),
          icon: "mdi-store",
          route: `/branch-drugs`,
          navigation: 'branch_drugs',
          permission: "view_branch_drugs"
        },
        {
          title: this.$t('components.sidebar.applications'),
          icon: "mdi-application-edit-outline",
          route: `/applications`,
          navigation: 'applications',
          permission: "view_applications"
        },
        {
          title: this.$t('components.sidebar.drugs'),
          icon: "mdi-pill",
          route: `/drugs`,
          navigation: 'dashboard',
          permission: "view_drugs"
        },
        // {
        //   title: this.$t('components.sidebar.families'),
        //   icon: "mdi-pill",
        //   route: `/families`,
        //   navigation: 'dashboard',
        //   permission: "view_families"
        // },
        {
          title: this.$t('components.sidebar.branches'),
          icon: "mdi-hospital-box",
          route: `/branches`,
          navigation: 'dashboard',
          permission: "view_branches"
        },
        {

          title: this.$t('components.sidebar.branch_groups'),
          icon: "mdi-home-group",
          route: `/branch-groups`,
          navigation: 'dashboard',
          permission: "view_branch_groups"
        },
        {
          title: this.$t('components.sidebar.warehouses'),
          icon: "mdi-warehouse",
          route: `/warehouses`,
          navigation: 'dashboard',
          permission: "view_warehouses"
        },
        {
          title: this.$t('components.sidebar.users'),
          icon: "mdi-account-multiple",
          route: `/users`,
          navigation: 'users',
          permission: "view_users"
        },
        {
          title: "Roles",
          icon: "mdi-account-multiple",
          route: `/roles`,
          navigation: 'roles',
          permission: "view_roles"
        },
      ]
    },
    permissions() {
      return this.$store.getters.permissions ?? []
    }
  },

  methods: {
    async actsCount() {
      let response = await window.axios.get("/api/acts/unread-count")
      if (response.data.ok)
        this.acts_count = response.data.data
    },
    reload() {
      this.$router.push('/', () => window.location.reload())
    },
    async logout() {
      await this.$store.dispatch('logout')
      this.$router.push('/login')
    },
  }
}
</script>


<style scoped>
.text-color {
  color: #7F95A3 !important;
}

.text-size {
  font-size: 18px !important;
  line-height: 15px !important;
  padding: 4px 0 !important;
}

.logout_icon {
  font-size: 30px !important;
  margin: 0 10px 0 0 !important;
  padding: 0 !important;
  cursor: pointer;
  width: 20px !important;
  height: 20px !important;
  border-radius: 50%;
}

.logout_icon:hover {
  background: #bbb !important;
  color: white !important;
}

.active {
  background: #01A1B7 !important;
}

.active-color {
  color: white !important;
}

.row {
  display: flex;
  justify-content: center;
  margin: 0;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.flex-row {
  display: flex;
  justify-content: center;
  align-items: center !important;
}

.active-back {
  background: #01A1B7 !important;
  border-radius: 5px;
}

.mr0 {
  margin-right: 0 !important;
}
</style>